<template>
  <filter-button
    :count-filtered="countFiltered"
    :disabled-clear="!isClearFilter"
    :disabled-apply="!isChangeFilter"
    @cancel="handleCancelFilter"
    @clear="handleClearFilter"
    @apply="handleSearchFilter"
  >
    <template v-slot:content>
      <v-list-item class="ml-5 mr-5 fixWidth d-block">
        <v-multiple-selected
          t-data="selector-filter"
          title="Branch"
          placeholder="All Branches"
          :branch="orgList.all"
          :selectedBranch="orgList.selected"
          :syncData="syncListSelectedData"
          :rule="() => true"
          :multiple="true"
          :is-changed="isChanged"
          :is-disabled="hasOneOrg"
          @resetIsChanged="isChanged = false"
        ></v-multiple-selected>
      </v-list-item>
    </template>
  </filter-button>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import MultipleSelected from '@/components/MultipleSelected'
import FilterButton from '@/components/FilterButton'
import {convertSelectedData} from '@/helper/helper'
var _ = require('lodash')

export default {
  name: 'FilterDropdown',
  data: () => ({
    countFiltered: 0,
    isChanged: false,
    appliedBranches: [],
  }),
  components: {
    FilterButton,
    'v-multiple-selected': MultipleSelected
  },
  computed: {
    isClearFilter() {
      const changedBranch = !_.isEqual(this.orgList.selected, [])
      if (this.hasOneOrg) return  false
      return changedBranch
    },
    isChangeFilter() {
      const changedBranch = !_.isEqual(this.orgList.selected, this.appliedBranches)
      if (this.hasOneOrg) return false
      return changedBranch
    },
    hasOneOrg() {
      return this.orgList.all.length === 1
    },
    ...mapState('featureSetup', ['orgList'])
  },
  methods: {
    sumFiltered() {
      const sumBranch = this.hasOneOrg || this.orgList.selected.length <= 0 ? 0 : 1
      return sumBranch
    },
    syncListSelectedData(data) {
      this.orgList.selected = []
      // for this page only
      if (!this.hasOneOrg) {
        this.orgList.selected = convertSelectedData(
          data,
          this.orgList.all,
          this.orgList.selected
        )
      }
    },
    handleSearchFilter() {
      this.$emit('apply')
      this.appliedBranches = this.orgList.selected
      this.fetch()
      this.countFiltered = this.sumFiltered()
    },
    handleClearFilter() {
      if (!this.hasOneOrg) this.orgList.selected = []
      this.isChanged = true
    },
    handleCancelFilter() {
      if (!this.hasOneOrg) {
        this.orgList.selected = this.appliedBranches
        this.isChanged = true
      }
      // wait for multiple select render
      this.$nextTick(() => {
        this.countFiltered = this.sumFiltered()
      })
    },
    ...mapActions('featureSetup', ['fetch'])
  }
}
</script>
