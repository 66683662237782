<template>
  <v-container class="px-10" fluid>
    <v-snackbar
      v-model="isCreateSuccess"
      width="500"
      color="success200Color"
      top
      centered
    >
      <div class="d-flex flex-row align-center mt-0">
        <v-img
          src="/images/snackbar-success.png"
          aspect-ratio="1"
          snackbar-image
          class="mt-0 mr-2 snackbar-image"
        ></v-img>
        <p class="snackbar-message sub-body-regular mb-0">
          Feature Setup display status have been updated successfully.
        </p>
      </div>
    </v-snackbar>

    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="linkRoute"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
        <h3 class="pageHeadline" t-data="page-headline">Feature Setup</h3>
        </div>
      </v-col>
    </v-row>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" t-data="search-panel">
            <search :search="search" label="Search Branch" @input="textSearch" @clear="clearSearch()"></search>
          </v-col>
          <v-col cols="12" sm="4"></v-col>
          <v-col cols="12" sm="2" class="d-flex justify-end align-center" t-data="filter-panel">
            <filter-dropdown @apply="handleApplyFilter"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-layout fluid v-resize="onResize">
        <v-data-table
          t-data="data-table"
          :headers="headers"
          :items="featureSetupList"
          :loading="isLoading"
          :options.sync="options"
          :items-per-page="pageSize"
          :server-items-length="total"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          item-key="id"
          :sort-desc="true"
          :must-sort="false"
          class="elevation-1 custom-table"
          fixed-header
          style="width: 100%"
          :height="windowSize.y - heightOptions.header - heightOptions.tableFooter"
        >
          <template v-slot:item.codename="{item}">
            <v-list-item-content
              class="d-inline-block text-truncate"
              style="width: 330px"
            >
              <v-list-item-title style="padding: 2px"> {{ item.name_th }}</v-list-item-title>
              <v-list-item-subtitle style="padding: 2px">{{ item.name }} ({{ item.codename.toUpperCase() }})</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-slot:item.active_feature="{item}">
            {{ item.active_feature }}/{{ item.total_feature }}
          </template>
          <template v-slot:item.update_date="{item}">{{ !!item.update_date ? dateFmt(item.update_date) : '' }}</template>
          <template v-slot:item.update_by_user="{item}"><username-status-text :user="item.update_by_user" /></template>
          <template v-slot:item.action="{item}">
          <div>
            <v-icon
              v-if="hasPermEdit()"
              t-data="edit-action"
              class="px-2 action-icon"
              @click="rowClick(item)"
              >mdi-pencil-outline</v-icon
            >
          </div>
        </template>
        </v-data-table>
      </v-layout>
      <div class="table-footer-prepend" t-data="total-result-content">
        <div class="vertical-center pl-4"><b>{{ total }}</b> Search results</div>
      </div>
    </v-card>

  </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import {
  convertDate,
  convertIntegerToCommaNumber,
  isStaff,
  searchHandler
} from '../../helper/helper'
import Search from '../../components/Search'
import FilterDropdown from './FilterDropdown'
import {limitExport, paymentMethod} from '@/constants/order'
import UsernameStatusText from '@/components/UsernameStatusText'

export default {
  components: {
    FilterDropdown,
    Search,
    UsernameStatusText,
  },
  name: 'list',
  data() {
    return {
      heightOptions: {
        header: 312,
        tableFooter: 59,
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      limitExport,
      orderCsvData: '',
      linkRoute: [
        {
          text: 'App Config',
          disabled: false,
          href: '/'
        },
        {
          text: 'Feature Setup',
          disabled: true,
          href: '/'
        }
      ],
      search: '',
      options: {},
      headers: [
        {text: 'Branch', align: 'left', value: 'codename', sortable: false, width: 268},
        {text: 'Active Feature', align: 'right', value: 'active_feature', sortable: false, width: 167},
        {text: 'Updated Date/Time', align: 'left', value: 'update_date', sortable: false, width: 196},
        {text: 'Updated By', align: 'left', value: 'update_by_user', sortable: false, width: 162},
        {text: 'Action', align: 'left', value: 'action', sortable: false, width: 82},
      ],
      pageSize: 25,
      itemsPerPage: [25, 50, 100],
      selectedStatus: 0,
      paymentMethodItems: paymentMethod,
      isCreateSuccess: false,
    }
  },
  computed: {
   ...mapState('featureSetup', [
     'isLoading',
     'featureSetupList',
     'total',
    ]),
  },
  async created() {
    this.setListOrgInfo()
    await this.fetch()
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setTextSearch('')
    this.resetList()
    next()
  },
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    hasPermEdit() {
      return isStaff()
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    textSearch(data) {
      this.search = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(this.search.trim())
        this.options.page = 1
        await this.fetch()
      })
    },
    clearSearch() {
      this.search = ''
      this.options.page = 1
      this.fetch()
    },
    convertNumToComma(value) {
      return convertIntegerToCommaNumber(value)
    },
    dateFmt: date => convertDate(date),
    rowClick(item) {
      this.$router.push({name: 'feature-setup-edit', params: {codename: item.codename}})
    },
    ...mapActions('featureSetup', [
      'fetch',
      'resetList',
      'setListOrgInfo',
      'setFieldSort',
      'setPage',
      'setPageSize',
      'setSort',
      'setTextSearch',
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        this.setPage(page)
        this.setFieldSort(sortBy[0])
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
  }
}
</script>
